// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

import $ from 'jquery';
import "bootstrap";
//import "jquery-viewport-checker"

// require('@client-side-validations/client-side-validations')
// require('@client-side-validations/simple-form/dist/simple-form.bootstrap4')

import "stylesheets/application";
import {email_copy} from './main';
window.email_copy = email_copy

$(document).on('turbolinks:load', function() {
    let width = $(window).width();
    //console.log(width);
    if (width>=1024) {
      $('.carousel.carousel-multi .carousel-item').each(function () {
      	var next = $(this).next();
      	if (!next.length) {
      		next = $(this).siblings(':first');
      	}
      	next.children(':first-child').clone().attr("aria-hidden", "true").appendTo($(this));

      	if (next.next().length > 0) {
      		next.next().children(':first-child').clone().attr("aria-hidden", "true").appendTo($(this));
      	}
      	else {
      		$(this).siblings(':first').children(':first-child').clone().appendTo($(this));
      	}
      });
    }
    // виправляє здвиг по навбару при плавному переміщені по якорям
    (function(document, history, location) {
      var HISTORY_SUPPORT = !!(history && history.pushState);

      var anchorScrolls = {
        ANCHOR_REGEX: /^#[^ ]+$/,
        OFFSET_HEIGHT_PX: 50,

        /**
         * Establish events, and fix initial scroll position if a hash is provided.
         */
        init: function() {
          this.scrollToCurrent();
          $(window).on('hashchange', $.proxy(this, 'scrollToCurrent'));
          $('body').on('click', 'a', $.proxy(this, 'delegateAnchors'));
        },

        /**
         * Return the offset amount to deduct from the normal scroll position.
         * Modify as appropriate to allow for dynamic calculations
         */
        getFixedOffset: function() {
          return this.OFFSET_HEIGHT_PX;
        },

        /**
         * If the provided href is an anchor which resolves to an element on the
         * page, scroll to it.
         * @param  {String} href
         * @return {Boolean} - Was the href an anchor.
         */
        scrollIfAnchor: function(href, pushToHistory) {
          var match, anchorOffset;

          if (!this.ANCHOR_REGEX.test(href)) {
            return false;
          }

          match = document.getElementById(href.slice(1));

          if (match) {
            anchorOffset = $(match).offset().top - this.getFixedOffset();
            $('html, body').animate({
              scrollTop: anchorOffset
            });

            // Add the state to history as-per normal anchor links
            if (HISTORY_SUPPORT && pushToHistory) {
              history.pushState({}, document.title, location.pathname + href);
            }
          }

          return !!match;
        },

        /**
         * Attempt to scroll to the current location's hash.
         */
        scrollToCurrent: function(e) {
          if (this.scrollIfAnchor(window.location.hash) && e) {
            e.preventDefault();
          }
        },

        /**
         * If the click event's target was an anchor, fix the scroll position.
         */
        delegateAnchors: function(e) {
          var elem = e.target;

          if (this.scrollIfAnchor(elem.getAttribute('href'), true)) {
            e.preventDefault();
          }
        }
      };

      $(document).ready($.proxy(anchorScrolls, 'init'));
    })(window.document, window.history, window.location);
});
